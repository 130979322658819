#fademenu {
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	li {
		display: block;
		margin: 15px;
		padding: 15px;
		text-align: center;
		border-radius: 6px;
	}
}

.parking-list {
	li {
		float: left;
		width: 46%;
		margin: 0 2% 10px;
	}
	button {
		display: inline-block;
		width: 100%;
		color: #1282c0;
		.name {
			font-size: 1.2rem;
			font-weight: bold;
		}
		span.active {
			display: block;
			height: 6px;
			margin-top: 10px;
			background: green;
		}
		span[data="1"] {
			background: red;
		}
	}
	button[data-type="K"] {
		border-color: #fcc691;
		color: #eb8f34;
	}
	button[data-type="T"] {
		border-color: #80cb86;
		color:#24a82f;
	}
}

.sample-color {
	display: inline-block;
	padding: 4px 10px;
	font-size: 0.8rem;
	color: #fff;
	border-radius: 6px;
  &.use {
    background: red;
  }
  &.vacant {
    background: green;
  }
}
