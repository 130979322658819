.dl {
	#site {
		max-width: 100% !important;
	}
  table {
		margin: 40px 0;
		width: 100%;
		font-size: 13px;
		line-height: 1.2;
		letter-spacing: 0;
	}
	th {
		padding: 3px 4px;
		text-align: center;
	}
	td {
		padding: 3px 4px;
		text-align: center;
	}
	td.row0 {
		background: #eee;
	}
}
