html {
	color: #555;
	font-size: 1em;
	line-height: 1.6;
	word-break: break-word;
	height: 100%;
}
hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}
audio {
	vertical-align: middle;
}
canvas {
	vertical-align: middle;
}
iframe {
	vertical-align: middle;
}
img {
	vertical-align: middle;
}
svg {
	vertical-align: middle;
}
video {
	vertical-align: middle;
}
fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}
textarea {
	resize: vertical;
}
p {
	margin: 0 0 1em;
	letter-spacing: 0;
}
table {
	border-top: 1px solid #ddd;
	border-left: 1px solid #ddd;
	border-collapse: collapse;
	border-spacing: 0;
}
th {
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}
td {
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}
button {
	padding: 10px;
	background: #f8f8f8;
	border: 2px solid #80d9f2;
	border-radius: 4px;
	letter-spacing: 0.1em;
	color: #156277;
	background: #ffffff;
	background: -moz-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
	background: -webkit-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
	background: linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#eeeeee",GradientType=0 );
	&.main {
    width: 80%;
    max-width: 280px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  &.sub {
    padding: 8px 5px;
    font-size: 14px;
    letter-spacing: 0;
  }
  &.input-edit {
    float: right;
    margin: 0;
    padding: 2px 5px;
    font-size: 0.8em;
  }
  &.input-set {
    float: right;
    height: 2.5em;
    margin: 0;
    padding: 0 5px;
    font-size: 0.8em;
  }
  &.protect {
    pointer-events: none;
    background: #eee;
    border-color: #ccc;
    color: #999;
  }
}
input[type=text] {
	padding: 4px;
	background: #f8f8f8;
	border: 1px solid #ddd;
	border-radius: 2px;
	letter-spacing: 0.1em;
  &[name=number_plate1] {
    width: 1em;
  }
  &[name=number_plate2] {
    width: 4em;
    &::-webkit-input-placeholder {
      font-size: 14px;
    }
  }
  &[name=number_area] {
    width: 6em;
  }
  &[name=passenger] {
    width: 2em;
  }
  
}
#ajax {
	&-wrapper {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url(../imgs/common/ajax-bg.png);
	}
	&-window {
		width: 90%;
		max-height: 80%;
		margin: 10% auto 0;
		padding: 20px;
		background: #fff;
		border-radius: 6px;
		box-sizing: border-box;
	}
	&-btn {
		position: fixed;
		top: 10px;
		right: 10px;
		width: 40px;
		height: 40px;
		background: #555;
		border: 1px solid #ccc;
		color: #fff;
		line-height: 40px;
		text-align: center;
		font-weight: bold;
		border-radius: 4px;
	}
	
}
.genericons {
	&:before {
		-webkit-font-smoothing: antialiased;
		font: normal 32px/1 "Genericons";
		vertical-align: middle;
	}
}
.strong {
	color: #156277;
	font-weight: bold;
	font-size: 1.2rem;
}
.center {
	text-align: center;
}
.menu {
	&-list {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	&-item {
		display: inline-block;
		margin: 10px 5px;
	}	
}
.clearfix {
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}

body {
	font-family: Arial, Helvetica, Meiryo, sans-serif;
	background: #ddd;
	letter-spacing: 0.1em;
	height: 100%;
}
#site {
	width: 100%;
	max-width: 900px;
	margin: 0 auto;
	background: #fff;
	.inner {
		padding: 15px;
	}	
}
.site-header {
	width: 100%;
	min-height: 50px;
	position: relative;
	background: #80d9f2;
	color: #156277;
}
.site-footer {
	font-size: 0.8rem;
	text-align: center;
	letter-spacing: 0;
	p {
		margin: 0;
	}
}
.page-title {
	padding: 10px 0;
	margin: 0 0 30px;
	font-size: 1.2rem;
	font-weight: bold;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	color: #156277;
}
.section-title {
	margin: 1em 0;
	padding: 0 0 0 12px;
	font-size: 1rem;
	border-left: 4px solid #80d9f2;
	color: #156277;
	span {
		font-size: 1.2em;
	}
}
#site-title {
	height: 50px;
	margin: 0;
	padding: 0;
	font-weight: bold;
	font-size: 20px !important;
	line-height: 50px;
	letter-spacing: 0;
	text-indent: 10px;
	a {
		color: #156277;
		text-decoration: none;
	}
}
#toggle-btn {
	display: inline-block;
	width: 40px;
	height: 40px;
	position: absolute;
	top: 5px;
	right: 10px;
	overflow: hidden;
	background: #fff;
	border-radius: 4px;
	letter-spacing: 0;
	.icon-menu {
		&:before {
			content: "\f419";
			display: inline-block;
			width: 40px;
			height: 40px;
			font: normal 24px/40px "Genericons";
			text-align: center;
		}
	}
  &.open {
    .icon-menu {
      &:before {
        content: "\f406";
      }
    }
  }
}
#gnav {
	display: none;
	background: #156277;
	* {
		margin: 0;
		padding: 0;
		color: #fff;
	}
	ul {
		list-style: none;
	}
	li {
		border-top: 1px solid #bbb;
		border-bottom: 1px solid #333;
	}
	a {
		display: block;
		padding: 8px 10px;
		text-decoration: none;
		.genericons {
			&:before {
				content: "\f429";
				font-size: 16px;
				line-height: 1px;
			}
		}
	}
}
