
.edit {
	#ajax-btn {
		display: none;
	}
}
#record-status {
	dl {
		border-top: 1px solid #ccc;
		border-left: 1px solid #ccc;
		font-size: 14px;
		line-height: 1.3;
	}
	dt {
		float: left;
		width: 7em;
		min-height: 1.4em;
		margin: 0;
		padding: 3px 5px;
	}
	dd {
		min-height: 1.4em;
		margin: 0;
		padding: 3px 5px 3px 8em;
		border-bottom: 1px solid #ccc;
		border-right: 1px solid #ccc;
		.red {
			font-weight: bold;
			color: red;
		}
	}
	form {
		text-align: center;
	}
}

/*-------------------------------------
 * Record Interface （画面）
 */
 #rif {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: url(../imgs/common/ajax-bg.png);
  ul {
		width: 300%;
		position: absolute;
		top: 40px;
		left: -100%;
		padding: 0;
		list-style: none;
	}
	li {
		display: none;
		float: left;
		margin: 0 15px;
		padding: 40px 20px;
		box-sizing: border-box;
		background: #fff;
		border-radius: 10px;
		text-align: center;
    &#q0 {
      display: block;
      min-height: 60px;
    }
    &#q1 {
      display: block;
      min-height: 60px;
    }
    &#q2 {
      display: block;
      min-height: 60px;
    }
    &.q1 {
      button {
        float: none;
      }
    }
    &.q2 {
      button {
        font-size: 18px;
        line-height: 1.3;
        span {
          display: block;
          font-size: 0.8em;
          font-weight: normal;
        }
      }
    }
		&.q3b {
			label {
				display: block;
				span {
					display: inline-block;
					padding: 0 10px 0 0;
				}
			}
		}
    &.q8 {
      label.other {
        padding: 10px;
        background: #f8f8f8;
        border: 2px solid #80d9f2;
        border-radius: 4px;
        letter-spacing: 0.1em;
        color: #156277;
        background: #ffffff;
        background: -moz-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
        background: -webkit-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
        background: linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#eeeeee",GradientType=0 );
        display: inline-block;
        input {
          width: 90%;
        }
      }
    }  
		button {
			float: left;
			width: 46%;
			margin: 10px 2%;
			font-size: 1.1em;
			font-weight: bold;
      &.sub {
        width: auto;
        font-size: 14px;
        font-weight: normal;
      }
      &[name=rif-back] {
        float: none;
        display: inline-block;
        width: auto;
        margin: 0 auto;
        padding: 5px 10px;
        font-size: 13px;
        font-weight: normal;
      }  
		}
		.btn-wrapper {
			clear: both;
			padding-top: 30px;
			text-align: center;
		}
		label {
			display: inline-block;
			margin: 0 0.5em 0.6em;
		}
		input[type=text] {
			max-width: 100%;
			font-size: 1.3em;
		}
	}
}


/*-------------------------------------
 * Record Edit （編集画面）
 */
#ret {
	dl {
		margin: 0 0 15px;
	}
	dt {
		display: block;
		padding: 8px 10px 4px;
		font-weight: normal;
		font-size: 0.8em;
		color: #999;
	}
	dd {
		display: block;
		min-height: 1.3em;
		margin: 0;
		padding: 4px 10px 4px;
		background: #eee;
		color: #156277;
    &.hidden {
      display: none;
    }
    &.nodata {
      &:not(.protect) {
        background: red;
      }
    }
    &.purpose {
      label {
        display: block;
        width: 90%;
        max-width: none;
        margin: 10px 0 0;
        span {
          display: block;
          font-size: 0.8em;
          color: #888;
        }
        input {
          width: 100%;
        }
      }
    }
    select {
			width: 400px;
			max-width: 70%;
			height: 2em;
			padding: 3px 5px;
			line-height: 2;
			border-color: #ccc;
			color: #555;
		}
		textarea {
			padding: 3px 5px;
			border-color: #ccc;
			border-radius: 4px;
		}
		.wrapper {
			padding: 0.4em 0;
			font-size: 14px;
			border-top: 1px dotted #ccc;
			label {
				width: 400px;
				max-width: 70%;
			}
			input {
				margin: 0 8px;
        &[disabled] {
          color: #aaa;
        }
      }
		}
	}
}
