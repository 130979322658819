.list {
	table {
		width: 100%;
		button {
			font-size: 0.9em;
		}
	}
	th {
		padding: 5px;
		text-align: center;
		background: #ccc;
	}
	td {
		padding: 5px;
		text-align: center;
	}
	tr {
		&:nth-child(odd) {
			background: #eee;
		}
	}
}